export const environment = {
  production: true,
  apiUrl: 'https://api-demo.shipskart.com',
  socketUrl: 'https://api-demo.shipskart.com',
  attachementUrl: 'https://shipskart-combined.s3.ap-south-1.amazonaws.com/',
  firebase: {
    apiKey: "AIzaSyDLnpDZ-jQ7GMXXqRukaOlmej-UQHKYzvE",
    authDomain: "shipskartbetav2web.firebaseapp.com",
    projectId: "shipskartbetav2web",
    storageBucket: "shipskartbetav2web.appspot.com",
    messagingSenderId: "636162001343",
    appId: "1:636162001343:web:100e20f0f1fbf12b8f390a"
  },
};