<!-- New Dashboard Design -->
<div class="container-fluid">
    <div class="panelBtn">
        <div>
            <h2 class="main-heading">Welcome, {{loggedInUserName|titlecase}} <span class="vendor-subhead"
                *ngIf="vendorData?.avg_rating != null && vendorData?.avg_rating != 0.0">
                <span class="rating-text"><a href="javascript:void(0)"
                        (click)="openRatingModal(vendorId)"
                        class="rating-box text-decoration-none">{{vendorData?.avg_rating}} <i class="fa fa-star"></i></a></span>
            </span></h2>
            <div *ngIf="vendorData?.delivery_strength">
                <span class="rating-text">Delivery Strength:
                    <span><b>{{vendorData?.delivery_strength}}</b>%</span></span>
            </div>
        </div>
        
        <div class="panel-all-buttons">
            <div class="btn-group rmargin10"
                *ngIf="role == userRole.ROLE_ADMIN ||role == userRole.ROLE_SUBADMIN">
                <button type="button"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    Dashboard
                </button>

                <div class="dropdown-menu">
                    <a class="dropdown-item"
                        (click)="openPanelModal();">
                        <i class="fa fa-plus-circle plus-sign req-btn"
                            aria-hidden="true"></i>
                        Customise your Dashboard
                    </a>
                    <a class="dropdown-item"
                        target="_blank"
                        *ngIf="role == userRole.ROLE_ADMIN"
                        [routerLink]="['/dashboard/create-form']"> <i class="fa fa-plus-circle plus-sign req-btn"
                            aria-hidden="true"></i> Create Form
                    </a>
                    <a class="dropdown-item"
                        (click)="viewFormModal(viewCreatedForms);"
                        *ngIf="role ==userRoles.ROLE_ADMIN || role ==userRoles.ROLE_SUBADMIN "> <i
                            class="fa fa-eye plus-sign req-btn"
                            aria-hidden="true"></i> View Form(s)
                    </a>
                    <a class="dropdown-item"
                        (click)="showReport(selectedReport);"
                        *ngIf="role ==userRoles.ROLE_ADMIN || role ==userRoles.ROLE_SUBADMIN "> <i
                            class="fa fa-eye plus-sign req-btn"
                            aria-hidden="true"></i> Hide Report
                    </a>
                </div>
            </div>
            <div class="btn-group rmargin10"
                *ngIf="role == userRole.ROLE_ADMIN ||role == userRole.ROLE_SUBADMIN">
                <button type="button"
                    class="btn btn-primary dropdown-toggle"
                    *ngIf="role == userRole.ROLE_ADMIN ||subAdminUploadFile"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    Import / Export
                </button>
                <div class="dropdown-menu">
                    <a class="dropdown-item"
                        (click)="fileName = null;openImportModal(importRequisitionQuotation);"><span
                            class="fa fa-upload"></span> Import Requirement
                    </a>
                    <a class="dropdown-item d-flex align-items-center"
                        (click)="exportRfqreport(rfqReport)"
                        *ngIf="role == userRole.ROLE_ADMIN ||role == userRole.ROLE_SUBADMIN"> <i
                            class="fa fa-download"></i>&nbsp; RFQ
                        Report
                    </a>
                </div>
            </div>
            <button *ngIf="role == userRole.ROLE_CAPTAIN"
                routerLink="/dashboard/catalogue"
                class="btn btn-primary create-req"><i class="fa fa-plus-circle plus-sign req-btn"
                    aria-hidden="true"></i> Create Requisition
            </button>
            <button class="btn btn-primary"
                *ngIf="role== userRole.ROLE_COMPANY_ADMIN && company.type==2"
                (click)="navigate()">
                RFQs From Shipskart
            </button>
            <button *ngIf="((role !==userRoles.ROLE_ADMIN) && (role !==userRoles.ROLE_SUBADMIN))"
                class="btn btn-primary"
                (click)="showReport(selectedReport);"> Hide Report
            </button>
            <button class="btn btn-primary create-req"
                *ngIf="!(role == userRole.ROLE_ADMIN ||role == userRole.ROLE_SUBADMIN)"
                (click)="openPanelModal();">
                <i class="fa fa-plus-circle plus-sign req-btn"
                    aria-hidden="true"></i>
                Customise your Dashboard
            </button>
            <div class="btn-group rmargin10"
                *ngIf="role == userRole.ROLE_ADMIN ||role == userRole.ROLE_SUBADMIN">
                <button type="button"
                    class="btn btn-primary"
                    aria-haspopup="true"
                    aria-expanded="false"
                    (click)="showModal(vendorRatingModal);vendorRate();"
                    *ngIf="role == userRole.ROLE_ADMIN ||role == userRole.ROLE_SUBADMIN">
                    <span><img src="/assets/icons/vendor_rating.png"
                            alt=""
                            width="15"></span> Vendor Rating
                </button>
            </div>

        </div>
    </div>
    <ng-container *ngIf="dataCard && dataCard.length>0"
        class="container-fluid">
        <app-card [data]="dataCard"
            (callParentMethodEvent)="updateData($event,true)"></app-card>
    </ng-container>
    <div class="row tmargin20">
        <div class="reward-container"
            *ngIf="rewardPoints && (role ==userRoles.ROLE_ADMIN || role ==userRoles.ROLE_SUBADMIN || role ==userRoles.ROLE_CAPTAIN || role == userRoles.ROLE_USER ||role ==userRoles.ROLE_COMPANY_ADMIN)">
            <div class="reward-info">
                <h3>Shipskart Reward Points</h3>
                <h2>{{rewardPoints?.toLocaleString()}}</h2>
                <p>{{'Expiry '+ (rewardPointsExpiry | date:'MMM y')}}</p>
            </div>
            <div class="icon-container">
                <img src="assets/images/award.svg"
                    width="129"
                    height="159">
            </div>
            <div class="instructions">
                <h3>Order, Earn, Redeem – Rewards with ShipsKart!</h3>
                <ul>
                    <li class="user-select"><i class="fa fa-check-circle"></i> <a href="javascript:void(0)"
                            class="text-decoration-none" style="cursor: default !important;">Place a Requisition </a> &nbsp; through the ShipsKart online
                        portal.</li>
                    <li class="user-select"><i class="fa fa-check-circle"></i> <a href="javascript:void(0)"
                            class="text-decoration-none" style="cursor: default !important;">Earn Reward Points </a> &nbsp; with every requisition created.</li>
                    <li class="user-select"><i class="fa fa-check-circle"></i> <a href="javascript:void(0)"
                            class="text-decoration-none" style="cursor: default !important;">Redeem your points </a>&nbsp; to unlock exciting prizes and
                        benefits.</li>
                </ul>
            </div>
        </div>
    </div>    
    <div class="row"
        id="drop-list">
        <div [ngStyle]="{'display': secDynamic?.report_type=='data_card'? 'none' : 'inline-block' }"
            [class]="(secDynamic?.report_type=='bar_graph'|| secDynamic?.report_type=='bar_graph_group' || secDynamic?.report_type=='line_graph'||secDynamic?.report_type=='line_graph_group' || (secDynamic?.report_type=='table' && secDynamic?.column_keys.length>=5))?'col-md-12':'col-md-6'"
            *ngFor="let secDynamic of dynamicSection"
            [ngSwitch]="secDynamic?.report_type">
            <div>
                <div class="tmargin15"
                    *ngSwitchCase="'pie_chart'">
                    <app-pie-chart [data]="secDynamic"
                        (callParentMethodEvent)="updateData($event,true)"></app-pie-chart>
                </div>
            </div>

            <div>
                <div class="tmargin15"
                    *ngSwitchCase="'line_graph'">
                    <app-line-chart [data]="secDynamic"
                        (callParentMethodEvent)="updateData($event,true)"></app-line-chart>
                </div>
            </div>

            <div>
                <div class="tmargin15"
                    *ngSwitchCase="'line_graph_group'">
                    <app-line-chart [data]="secDynamic"
                        (callParentMethodEvent)="updateData($event,true)"></app-line-chart>
                </div>
            </div>

            <div>
                <div class="tmargin15"
                    *ngSwitchCase="'bar_graph'">
                    <app-bar-chart [data]="secDynamic"
                        (callParentMethodEvent)="updateData($event,true)"></app-bar-chart>
                </div>
            </div>

            <div>
                <div class="tmargin15"
                    *ngSwitchCase="'bar_graph_group'">
                    <app-bar-chart [data]="secDynamic"
                        (callParentMethodEvent)="updateData($event,true)"></app-bar-chart>
                </div>
            </div>

            <div>
                <div class="custom-table tmargin15"
                    *ngSwitchCase="'table'">
                    <app-table [data]="secDynamic"
                        (callParentMethodEvent)="updateData($event,true)"></app-table>
                </div>
            </div>

            <div>
                <div *ngSwitchCase="'barChartHorizontal'"
                    class="tmargin15">
                    <app-bar-chart (callParentMethodEvent)="updateData($event,true)"></app-bar-chart>
                </div>
            </div>
            <!-- <div class="custom-table tmargin30">
                <app-table></app-table>
            </div> -->

        </div>

        <app-panel (callParentMethodEvent)="updateData($event,true)"
            #panelModal
            [panelData]="panelData"></app-panel>

        <app-feedback-form #feedbackModal
            (closeForm)="isFeedbackFormModelOpen=false"
            (submitted)="onChildSubmitted($event)"></app-feedback-form>
    </div>

    <!-- Shimmer UI effect for show fake ui -->
    <div class="row">
        <div *ngFor="let item of dataSections"
            class="card custom-table col-md-12">
            <div class="loader-shimmer-banner shimmer-animation"></div>
            <div class="loader-shimmer-content">
                <div class="loader-shimmer-header">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row mr-0">
        <div class="col-md-12 tmargin15 border"
            *ngIf="formsToSubmit.length>0">
            <div class="table-modal-header">
                <h4>Forms To Submit</h4>
                <div *ngIf="role ==userRoles.ROLE_ADMIN || role ==userRoles.ROLE_SUBADMIN ">
                    <ng-select [items]="usersData"
                        bindLabel="name"
                        bindValue="userId"
                        [multiple]="false"
                        placeholder="Select User"
                        class="form-control"
                        [clearable]="false">
                    </ng-select>
                </div>
            </div>
            <div class="table-modal-body-container">
                <div class="table-modal-body">
                    <table class="table table-bordered">
                        <thead class="thead">
                            <tr>
                                <th class="text-center">
                                    Sr. No
                                </th>
                                <th class="text-center">
                                    Form Name
                                </th>
                                <th class="text-center">
                                    Assigned By
                                </th>
                                <th class="text-center">
                                    Created On (DD-MM-YYYY)
                                    <!--                              <div class="text-nowrap">Created On</div>-->
                                    <!--                              <div class="text-nowrap">(DD-MM-YYYY)</div>-->
                                </th>
                                <th class="text-center">
                                    View Form
                                </th>
                                <th class="text-center">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of formsToSubmit; let i = index"
                                class="tr-hover">
                                <td class="text-center">
                                    {{i+1}}
                                </td>
                                <td class="text-center">
                                    {{item?.form?.form_title}}
                                    <p *ngIf="item && item.order_details"
                                        class="subcat">Order No. : <b>{{item.order_details.order_number}}</b></p>
                                </td>
                                <td class="text-center">
                                    {{item.from_user.fname | capitalize}}
                                </td>
                                <td class="text-center">
                                    {{item?.created_at|date : "dd-MM-YYYY"}}
                                </td>
                                <td class="text-center hyperlink">
                                    <a
                                        (click)="openFeedbackModal(item?.form_id, (item?.order_id !== null && item?.order_id !== undefined) ? item?.order_id : 0,selectUser)">View</a>
                                </td>
                                <td class="text-center"
                                    [ngClass]="{pendingStatus: item?.status=='Pending', submittedStatus: item?.status=='Submitted'}">
                                    {{item?.status}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>



<!-- Import Requisition Modal-->
<ng-template #importRequisitionQuotation>
    <div class="modal-body padding30 text-center">
        <h4 class="sure-font bmargin20">Import Request for Quotation</h4>
        <div class="text-left form-group"
            [ngClass]="{error : submitted && !selectFile}">
            <div class="head-filters bmargin15">
                <label class="bmargin0">Select Category<span class="mendatory">*</span></label>
                <ng-select [items]="category"
                    [loading]="isCategoryLoading"
                    (change)="categorySelected($event)"
                    bindLabel="name"
                    bindValue="id"
                    [multiple]="false"
                    placeholder="Category"
                    class="form-control reduce-height"
                    [clearable]="false">
                    <ng-template ng-option-tmp
                        let-item="item">
                        <a title="{{item?.name}}">{{item?.name}}</a>
                    </ng-template>
                </ng-select>
            </div>
            <label>Select File<span class="mendatory">*</span></label>
            <div class="select-file drop-file">
                <span class="btn-span">+</span>
                <input type="file"
                    accept=".xlsx,.xls,.docx,.pdf"
                    multiple
                    #inputFile
                    class="form-control"
                    (change)="selectedfileName($event.target.files)" /> {{fileName || 'File Name'}}
            </div>
            <small class="note-color color-000">Note: File type should be in (.xlsx, .docx, .pdf)</small><br>
            <small *ngFor="let name of fileNameList; index as i"><b>{{i+1}}</b>. {{name.name}} <br> </small>
            <div class="errorbox"
                *ngIf="submitted && !selectFile">
                <span>Select a file</span>
            </div>
            <div class="errorbox"
                *ngIf="inValidFileSize">
                File is larger than <strong>5mb !!</strong>
            </div>
        </div>
        <div class="text-right">
            <button class="btn btn-outline-primary"
                (click)="modalRef.hide();fileNameList = [];fileName = null;selectFile = false;submitted=false;inValidFileSize = false"
                type="button">Cancel</button>
            <button type="submit"
                [disabled]="((!isLoading) && categoryId==0)"
                (click)="uploadReqQuotation()"
                class="btn btn-primary text-white">Upload
                <ng-container *ngIf="isLoading">
                    <span class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                    <span class="sr-only">Loading...</span>
                </ng-container>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #viewCreatedForms>
    <div class="fluid-container">
        <div class="table-modal-header">
            <h4>{{this.role==1?"Manage ":"Assigned "}}Forms</h4>
            <button type="button"
                aria-label="Close"
                class="close"
                aria-label="Close"
                (click)="createdFormModal.hide()">
                <span aria-hidden="true">&#215;</span>
            </button>
        </div>
        <div class="table-modal-body">
            <div class="h-scroll">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th class="text-center">
                                Form Name
                            </th>
                            <th class="text-center">
                                Created By
                            </th>
                            <th class="text-center">
                                Created On (DD-MM-YYYY)
                            </th>
                            <th class="text-center">
                                View Form
                            </th>
                            <th class="text-center">
                                Submissions
                            </th>
                            <th class="text-center">
                                Assign Form
                            </th>
                            <th class="text-center">
                                Copy Link<br>
                                (For Guest User)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of feedbackForms; let i = index">
                            <td class="text-center">
                                {{item.form_title | titlecase}}
                            </td>
                            <td class="text-center">
                                {{item.created_by===1?"Admin":""}}
                            </td>
                            <td class="text-center">
                                {{item.created_at|date : "dd-MM-YYYY"}}
                            </td>
                            <td class="text-center hyperlink">
                                <a (click)="onViewClick(item.id)">View</a>
                            </td>
                            <td class="text-center hyperlink">
                                <a target="_self"
                                    (click)="navigateUrl(item.id)"
                                    routerLinkActive="router-link-active">View Submission</a>
                            </td>
                            <td class="text-center hyperlink">
                                <a (click)="viewFormAssignmentModal(viewAssignmentDetails, item.id);"
                                    *ngIf="item.id!=74">
                                    Assign</a>
                                <!-- <a *ngIf="item.id==74">Not Assignable</a> -->
                            </td>
                            <td class="text-center hyperlink">
                                <a (click)="copyLink(item.id);"
                                    *ngIf="item.id!=74">
                                    Copy Link</a>
                                <!-- <a *ngIf="item.id==74">Can't be Copied</a> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #viewAssignmentDetails>
    <div class="fluid-container">
        <div class="table-modal-header">
            <h4>Assign Feedback Form To Users</h4>
            <button type="button"
                aria-label="Close"
                class="close"
                aria-label="Close"
                (click)="assignFormModal.hide()">
                <span aria-hidden="true">&#215;</span>
            </button>
        </div>
        <div class="table-modal-body-form">
            <form [formGroup]="assignForm"
                (ngSubmit)="onAssignFormSubmit();">
                <div class="row pl-3">
                    <div class="col-md-12 bmargin10"
                        *ngIf="formId==39">
                        <label class="form-label bmargin5 custom-font"
                            for="order-id">Assign OrderID<span class="required">*</span></label>
                        <input type="text"
                            formControlName="order_id"
                            class="form-control"
                            disabled="disableUser"
                            id="order-id"
                            placeholder="Enter Order ID">
                    </div>
                    <div class="col-md-12 bmargin10">

                        <div class="row">
                            <div class="col-md-4">
                                <label class="form-label bmargin5 custom-font"
                                    for="form-user">Assign By Users<span class="mendatory">*</span></label>
                                <ng-select [items]="users"
                                    [clearOnBackspace]="true"
                                    [clearSearchOnAdd]="true"
                                    bindLabel="fname"
                                    bindValue="id"
                                    formControlName="to_user_ids"
                                    [multiple]="true"
                                    [loading]="userDataLoader"
                                    id="form-user"
                                    [clearable]="false"
                                    (change)="selectToAssignUser($event)"
                                    [closeOnSelect]="false">
                                    <ng-template ng-multi-label-tmp
                                        let-items="items"
                                        let-clear="clear">
                                        <div *ngFor="let item of items"
                                            [ngClass]="{isAssigned: item.form_status }">
                                            <span class="tag"
                                                aria-hidden="true"
                                                (click)="clear(item)">
                                                <i class="fa fa-times"></i>
                                            </span>
                                            <span>{{ item.fname }}</span>
                                        </div>
                                    </ng-template>
                                    <ng-template ng-option-tmp
                                        let-item="item">
                                        <span [ngClass]="{isAssignedText: item.form_status }">{{
                                            item.fname }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <!-- <div class="col-md-4">
                                <ng-select [items]="assignedRoleIds"
                                    bindLabel="name"
                                    bindValue="userId"
                                    formControlName="role_ids"
                                    [multiple]="false"
                                    placeholder="Select Role"
                                    id="form-user"
                                    class="form-control"
                                    [clearable]="false"
                                    [closeOnSelect]="false">
                                </ng-select>
                            </div> -->
                            <div class="col-md-4">
                                <label class="form-label bmargin5 custom-font"
                                    for="form-user">Assign By Designation<span class="mendatory">*</span></label>
                                <ng-select [items]="designationList"
                                    bindLabel="name"
                                    bindValue="id"
                                    [loading]="loadDesignation"
                                    formControlName="designation_ids"
                                    [multiple]="true"
                                    id="form-user"
                                    (change)="changeDesignation($event)"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [clearOnBackspace]="true"
                                    [clearSearchOnAdd]="true">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 text-right">
                        <button class="btn btn-primary"
                            type="submit"
                            [disabled]="!assignForm.valid">
                            <ng-container *ngIf="isLoadingLoader">
                                <span class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"></span>
                                <span class="sr-only">Loading...</span>
                            </ng-container>&nbsp;Assign Form</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<!-- Vendorrating Modal -->
<ng-template #vendorRatingModal>
    <div class="vendor-popup">
        <h4 class="sure-font text-center font22 weight600 tmargin10 bmargin20"><span><i
                    class="fa fa-star animated-star"></i></span> Top 20 Vendor Companies <i
                class="fa fa-star animated-star"></i><a class="popup-close"
                (click)="modalRef.hide()">&#10006;</a></h4>
        <h4 class="text-center tmargin10 loading"
            *ngIf="showLoading">Loading...</h4>
        <div class="tabular-detail data-table batch remove-margin"
            *ngIf="!showLoading">
            <div class="scroll-table">
                <table class="table full-table req-table price update new batch-info">
                    <thead>
                        <tr>
                            <th width="50">S.No.</th>
                            <th width="350">Vendor Company Name</th>
                            <th>Vendor Rating</th>
                            <th>Delivery Strength</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let vendor of vendorRates; index as i">
                            <td>{{i+1}}.</td>
                            <td><a href="javascript:void(0)"
                                    routerLink="/company/details/{{vendor.id}}"
                                    (click)="modalRef.hide()">{{vendor.name | titlecase}}</a></td>
                            <td>
                                <div class="avgrating text-center"
                                    [ngClass]="{
                                'best' : vendor.average_ratings > 3.5,
                                'good' : (vendor.average_ratings <= 3.5 && vendor.average_ratings >2),
                                'low':  vendor.average_ratings <=2}">
                                    <span (click)="openRatingModal(vendor.id)"><span
                                            class="weight600">{{vendor.average_ratings}} </span><i
                                            class="fa fa-star"></i></span>
                                </div>
                            </td>
                            <td><span class="weight700">{{vendor.delivery_strength}}%</span></td>
                        </tr>
                        <tr *ngIf="vendorRates.length==0">
                            <td colspan="4">No Vendor Rating Available!</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="view text-right"
            *ngIf=" vendorRates && vendorRates.length>0"><a routerLink="/company"
                [queryParams]="{ type: 2 }"
                (click)="modalRef.hide()">View All</a></div>
    </div>
</ng-template>

<!-- Import Rfq Modal -->
<ng-template #rfqReport>
    <div class="rfq-filteroptions">
        <a href="javascript:void(0)"
            (click)="rfqReportModal.hide()"
            class="close-filter-btn">&#215;</a>
        <label class="required-some-data"
            *ngIf="msg">Please Enter Some Value to Filter Data.</label>
        <form [formGroup]="filterForm"
            (ngSubmit)="filterData()">
            <div class="row">
                <div class="col-md-6 col-sm-6 bmargin20">
                    <label for="">Date Range (Import Date)</label>
                    <div class="row">
                        <div class="col-md-6">
                            <p-calendar appCalendarUiCorrection
                                formControlName="from_date"
                                placeholder="From Date"
                                dateFormat="yy-mm-dd"
                                [maxDate]="maxDateValue"
                                [monthNavigator]="true"
                                [yearNavigator]="true"
                                yearRange="2000:2099"
                                (onSelect)="onChangeEta($event)">
                            </p-calendar>
                        </div>
                        <div class="col-md-6">
                            <p-calendar appCalendarUiCorrection
                                formControlName="to_date"
                                placeholder="To Date"
                                dateFormat="yy-mm-dd"
                                [minDate]="minDate"
                                [maxDate]="maxDateValue"
                                [monthNavigator]="true"
                                [yearNavigator]="true"
                                yearRange="2000:2099">
                            </p-calendar>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 bmargin20">
                    <label for="">Sub - Admin</label>
                    <ng-select [items]="subAdminList"
                        bindLabel="name"
                        bindValue="id"
                        [multiple]="false"
                        formControlName="sub_admin_user"
                        [loading]="loading"
                        placeholder="Select User"
                        class="form-control">
                    </ng-select>
                </div>
            </div>
            <div class="row ml-0">
                <div class="col-md-12">
                    <input class="btn btn-outline-primary"
                        type="button"
                        value="Clear Filter"
                        (click)="resetForm()" />
                    <button class="btn btn-primary"
                        [disabled]="false"
                        type="submit"> <span [ngClass]="{'loading-spinner small':rfqLoading}"></span> Apply</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #assignedUser>
    <div class="assign-user-popup-container">
        <h3 class="text-center tmargin10 bmargin10">
            Select User
        </h3>
        <div class="bmargin10">
            <label for="">User Name</label>
            <ng-select [items]="userList"
                bindLabel="fname"
                bindValue="id"
                [(ngModel)]="selectedUser"
                (change)="changeUser($event)"
                placeholder="Select User">
            </ng-select>
        </div>
    </div>
</ng-template>

<ng-template #selectedReport>
    <div class="m-3">
        <button type="button"
            aria-label="Close"
            class="close"
            aria-label="Close"
            (click)="showReports.hide()">
            <span aria-hidden="true">&#215;</span>
        </button>
        <h3>Hide Reports</h3>
        <hr>
        <p>Select the reports you wish to hide from your dashboard.</p>
        <ul class="tmargin20">
            <li *ngFor="let report of dataSection;let i =index"
                class="report-list">
                <input type="checkbox"
                    (change)="removeReport($event,report,i)"
                    [checked]="report.isChecked">
                {{report.report_title}}
            </li>
        </ul>
        <button class="btn btn-primary hidebtn"
            (click)="submitHidereport()">Confirm Hide </button>
    </div>
</ng-template>
<app-order-rating #orderRating></app-order-rating>